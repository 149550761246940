import React, { useEffect, useRef, useState } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import requests from "./axios/requests";
import { Routes, Route } from "react-router-dom";

import Preview from "./sections/Preview/Preview";
import Header from "./components/Header/Header";
import Service from "./sections/Service/Service";
import CompanyAbout from "./sections/CompanyAbout/CompanyAbout";
import StartProject from "./sections/StartProject/StartProject";
import ProductsCompany from "./sections/ProductsCompany/ProductsCompany";
import ReleaseProjects from "./sections/ReleaseProjects/ReleaseProjects";
import CompanyModal from "./sections/CompanyAbout/CompanyModal/CompanyModal";
import PageRelease from "./components/PageRelease/PageRelease";

function App() {
    const [pageApi, setPageApi] = useState("");
    const [dataCompany, setDataCompany] = useState("");
    const [dataServices, setDataServices] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [redirectService, setRedirectService] = useState("");

    const refPreview = useRef();
    const refServices = useRef();
    const refCompanyAbout = useRef();
    const refReleaseProjects = useRef();
    const refProducts = useRef();
    const refFeedback = useRef();

    let scrollTo = (currentPage) => {
        switch (currentPage) {
            case "service":
                if (refServices?.current) {
                    /* refServices.current.scrollIntoView();
                     let scrolledY = window.scrollY;
                     if (scrolledY) {
                         window.scrollTo({
                             top: scrolledY - 87,
                         });
                     }*/
                    refServices.current.scrollIntoView({block: "start", behavior: "smooth"})
                }
                break;
            case "preview":
                if (refPreview?.current)
                    refPreview.current.scrollIntoView({block: "start", behavior: "smooth"})
                break;
            case "aboutCompany":
                if (refCompanyAbout?.current) {
                    refCompanyAbout.current.scrollIntoView({block: "start", behavior: "smooth"})
                }
                break;
            case "releaseProjects":
                if (refReleaseProjects?.current) {
                    refReleaseProjects.current.scrollIntoView({block: "start", behavior: "smooth"})
                }
                break;
            case "products":
                if (refProducts?.current) {
                    refProducts.current.scrollIntoView({block: "start", behavior: "smooth"})
                }
                break;
            case "feedback":
                if (refFeedback?.current) {
                    refFeedback.current.scrollIntoView({block: "start", behavior: "smooth"})
                }
                break;
        }
    }
    let scrollToTop = () => {
    window.scroll(0, 0)
    }

    useEffect(() => {
       setTimeout(() =>  scrollToTop(), 110)

        requests.fetch_company_info()
            .then(res => setDataCompany(res.data))
            .catch(err => console.error(err))
        requests.fetch_services()
            .then(res => setDataServices(res.data))
            .catch(err => console.error(err))
    }, [])
    useEffect(() => {
        if (openModal)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'unset';
    }, [openModal]);
    return (
        <div className="app">
            <Header
                pageApi={pageApi}
                openModal={openModal}
                scrollToView={scrollTo}
            />
            {/*<ReactFullpage
                normalScrollElements={".inner"}
                onLeave={(origin, destination, direction) => {
                    setOpenModal(false);
                }}
                render={({state, fullpageApi}) => {
                    setPageApi(fullpageApi);
                    return (
                        <div>
                            <Preview dataCompany={dataCompany}/>
                            <Service
                                dataServices={dataServices}
                            />
                            <ReleaseProjects/>
                            <ProductsCompany/>
                            <CompanyAbout
                                dataCompany={dataCompany}
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                            />
                            <StartProject
                                dataServices={dataServices}
                            />
                        </div>
                    );
                }}
            />*/}
            <Routes>
                <Route
                    path={"/"}
                    element={[
                        <Preview
                            dataCompany={dataCompany}
                            refPreview={refPreview}
                        />,
                        <Service
                            dataServices={dataServices}
                            refServices={refServices}
                            setRedirectService={(value) => {
                                setRedirectService(value)
                                scrollTo("feedback")
                            }}
                        />,
                        <ProductsCompany
                            refProducts={refProducts}
                        />,
                        <ReleaseProjects
                            refReleaseProjects={refReleaseProjects}
                            scrollToTop={scrollToTop}
                        />,
                        <CompanyAbout
                            dataCompany={dataCompany}
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            refCompanyAbout={refCompanyAbout}
                        />
                    ]}
                />
                <Route
                    path={"/release-project/:id"}
                    element={<PageRelease/>}
                />
                <Route
                    path={"/release-product/:id"}
                    element={<PageRelease/>}
                />
            </Routes>
            <StartProject
                dataServices={dataServices}
                refFeedback={refFeedback}
                redirectService={redirectService}
                setRedirectService={(value) => {
                    setRedirectService(value)
                    scrollTo("feedback")
                }}
            />
            <CompanyModal
                openModal={openModal}
                closeModal={() => setOpenModal(false)}
                dataCompany={dataCompany}
            />
        </div>
    );
}

export default App;
