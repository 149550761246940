import React, { useEffect, useState } from 'react';
import './ProductsCompany.scss';
import figures from "../../assets/figures.png";
import skillometer_icon from "../../assets/skillometer_icon.png";
import skillometer_preview from "../../assets/skillometer_preview.png";
import active_checkbox from "../../assets/active_checkbox.svg";
import alan_icon from "../../assets/alan_icon.png";
import requests from "../../axios/requests";

const ProductsCompany = ({refProducts}) => {
    const [data, setData] = useState([]);


    let convertNameSkillometer = (str) => {
        let result = {
            first: "",
            another: ""
        }
        if (str) {
            let splitStr = str.split(" ");
            result.first = splitStr[0].replace(/[*]/g, "");
            result.another = splitStr.slice(1).join(" ");
        }
        return result;
    }
    let convertNameAlan = (str) => {
        let result = {
            first: "",
            another: ""
        }
        if (str) {
            let splitStr = str.split(" ");
            result.first = splitStr.slice(0, splitStr.length - 2).join(" ");
            result.another = `${splitStr[splitStr.length - 2].replace(/[*]/g, "")} ${splitStr[splitStr.length - 1].replace(/[*]/g, "")}`;
        }
        return result;
    }

    useEffect(() => {
        requests.fetch_products()
            .then(res => setData(res.data))
            .catch(err => console.error(err))
    }, [])
    return (
        <div
            className="products_company"
            ref={refProducts}
        >
            <div className="container">
                <div className="title">
                    <span>продукты</span>
                    <span>компании</span>
                </div>
                <div className="content">
                    {data.length > 0 &&
                        data.map((elem, indx) => {
                                if (indx === 0)
                                    return (
                                        <div
                                            className="col"
                                            key={elem.id}
                                            onClick={() => {
                                                if (elem.url)
                                                    window.open(elem.url, "_blank");
                                            }}
                                            style={elem.url ? {cursor: "pointer"} : {}}
                                        >
                                            <div className="figures">
                                                <img src={figures} alt="figures"/>
                                            </div>
                                            <div className="main">
                                                <div className="icon">
                                                    <img src={skillometer_icon} alt="skillometer_icon"/>
                                                </div>
                                                <div className="text">
                                                    <span>Платформа для повышения
                                                        <span style={{color: "#00A72F"}}>
                                                            {" "}
                                                            профессиональных навыков
                                                            {" "}
                                                        </span>
                                                        с применением ИИ
                                                    </span>
                                                    {/*<span>{convertNameSkillometer(elem.name).first} </span>
                                                    <span>{convertNameSkillometer(elem.name).another} </span>*/}
                                                </div>
                                                <div className="info">
                                                    {/*{elem?.description}*/}
                                                    На платформе внедрена инновационная система построения
                                                    индивидуальной образовательной траектории на основе данных
                                                    о рынке труда с высокой вариативностью для обеспечения
                                                    комфортного порога входа в любую профессию. Помогает
                                                    автоматически генерировать детальный учебный план для освоения
                                                    любых профессиональных навыков. Является примером совмещения
                                                    двух систем ИИ.
                                                </div>
                                                <div className="list">
                                                    <li key={indx}>
                                                        <img src={active_checkbox} alt="active_checkbox"/>
                                                        <span>Конструктор курсов</span>
                                                    </li>
                                                    <li key={indx}>
                                                        <img src={active_checkbox} alt="active_checkbox"/>
                                                        <span>Генерация учебного плана</span>
                                                    </li>
                                                    <li key={indx}>
                                                        <img src={active_checkbox} alt="active_checkbox"/>
                                                        <span>Аналитика данных</span>
                                                    </li>
                                                    {/*  {elem?.item_list?.length > 0 &&
                                                        elem.item_list.map((item, indx) =>
                                                            <li key={indx}>
                                                                <img src={active_checkbox} alt="active_checkbox"/>
                                                                <span>{item}</span>
                                                            </li>
                                                        )
                                                    }*/}
                                                </div>
                                            </div>
                                            <div className="skillometer_preview">
                                                <img src={skillometer_preview} alt="skillometer_preview"/>
                                            </div>
                                        </div>
                                    )
                                else
                                    return (
                                        <div
                                            className="col"
                                            onClick={() => {
                                                if (elem.url)
                                                    window.open(elem.url, "_blank");
                                            }}
                                            style={elem.url ? {cursor: "pointer"} : {}}
                                            key={elem.id}
                                        >
                                            <div className="main">
                                                <div className="icon">
                                                    <img src={alan_icon} alt="alan_icon"/>
                                                </div>
                                                <div className="text">
                                                    <span>Платформа для проверки </span>
                                                    <span> знаний персонала по ОТ и ПБ</span>
                                                    {/*<span>{convertNameAlan(elem.name).first} </span>*/}
                                                    {/*<span>{convertNameAlan(elem.name).another}</span>*/}
                                                </div>
                                                <div className="info">
                                                    {/*{elem?.description}*/}
                                                    Предназначена для автоматизации производственного контроля
                                                    в условиях отсутствия связи в режиме удаленной работы.
                                                    Результаты тестирования персонала, чек-листы, НПА хранятся в
                                                    памяти мобильного устройства и передаются при наличии мобильной
                                                    связи на центральный сервер.
                                                </div>
                                                <div className="list">
                                                    <li key={indx}>
                                                        <img src={active_checkbox} alt="active_checkbox"/>
                                                        <span>Тестирование</span>
                                                    </li>
                                                    <li key={indx}>
                                                        <img src={active_checkbox} alt="active_checkbox"/>
                                                        <span>Чек-листы</span>
                                                    </li>
                                                    <li key={indx}>
                                                        <img src={active_checkbox} alt="active_checkbox"/>
                                                        <span>Аналитика данных</span>
                                                    </li>
                                                    {/*{elem?.item_list?.length > 0 &&
                                                        elem.item_list.map((item, indx) =>
                                                            <li key={indx}>
                                                                <img src={active_checkbox} alt="active_checkbox"/>
                                                                <span>{item}</span>
                                                            </li>
                                                        )
                                                    }*/}
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default ProductsCompany;