import React, { useEffect, useState } from 'react';
import InputMask from "react-input-mask";
import requests from "../../axios/requests";
import Footer from "../Footer/Footer";
import { message as messageAntd, Spin } from "antd";
import cn from 'classnames';

import arrow from '../../assets/arrow.svg';
import file_upload from '../../assets/file_upload.svg';
import './StartProject.scss';

const StartProject = ({dataServices, refFeedback, redirectService, setRedirectService}) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);
    const [keyInput, setKeyInput] = useState(1);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [checkedAgreement, setCheckedAgreement] = useState(false);

    const [activeSelect, setActiveSelect] = useState("");
    const [activeSelectService, setActiveSelectService] = useState(redirectService || "");
    const [flagSelect, setFlagSelect] = useState(false);
    const [flagSelectService, setFlagSelectService] = useState(false);
    const [validationFlag, setValidationFlag] = useState({
        name: false,
        phone: false,
        budget: false,
        message: false
    });

    const salary = [
        "До 1 млн рублей",
        "От 1 млн рублей",
        "От 3 до 5 млн рублей",
        "От 5 до 10 млн рублей",
        "От 10 млн рублей"
    ];
    let currentBudgetValue = (str) => {
        switch (str) {
            case salary[0]:
                return "up_to_1"
            case salary[1]:
                return "1_3"
            case salary[2]:
                return "3_5"
            case salary[3]:
                return "5_10"
            case salary[4]:
                return "from_10"
            default:
                return "up_to_1"
        }
    }
    let handleSendApplication = () => {
        let errors = [];

        if (file && (file?.size / 1000) > 64000) {
            messageAntd.error("Размер загружаемого файла превышает 64мб", 3)
            return
        }
        if (!name) errors = [...errors, "name"];
        if (!phone || phone.includes("_")) errors = [...errors, "phone"];
        if (!activeSelect) errors = [...errors, "budget"];
        if (!message) errors = [...errors, "message"];
        if (errors.length > 0) {
            messageAntd.error("Заполните обязательные поля", 2)
            for (let i = 0; i < errors.length; i++) {
                setValidationFlag(prev => ({...prev, [errors[i]]: true}))
                setTimeout(() => {
                    setValidationFlag(prev => ({...prev, [errors[i]]: false}))
                }, 2000)
            }
            return
        }

        let data = new FormData();
        if (activeSelectService) data.append(
            "service", dataServices.find(elem => elem.name === activeSelectService)?.id
        );
        if (file) data.append("file", file);
        if (email) data.append("email", email);
        data.append("name", name);
        data.append("phone", phone);
        data.append("message", message);
        data.append("budget", currentBudgetValue(activeSelect));

        requests.fetch_application(data, (progress) => setLoadingUpload(true)
            /*let percentCompleted = Math.round((progress.loaded * 100) / progress.total)*/)
            .then(() => {
                messageAntd.success("Заявка успешно отправлена.")
                setLoadingUpload(false)
            })
            .catch(err => {
                if (err?.response?.data?.phone?.length > 0) {
                    messageAntd.error(err.response.data.phone[0])
                }
                console.error(err)
                setLoadingUpload(false)
            })
    }

    useEffect(() => {
        setActiveSelectService(redirectService)
    }, [redirectService])
    return (
        <div
            className="start_project"
            ref={refFeedback}
        >
            <div className="container">
                <div className="content">
                    <div className="left_col">
                        <div className="h1">
                            начать
                            проект
                        </div>
                        <div className="text">
                            Расскажите нам о своем проекте
                            и мы свяжемся с вами в кратчайшие сроки
                        </div>
                    </div>
                    <div className="form_section">
                        <div className="row">
                            <div className="input_container">
                                <input
                                    className={validationFlag.name ? "input_form error" : "input_form"}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder={"Имя"}
                                    type="text"
                                />
                                <div className="required">*</div>
                            </div>
                            <div className="input_container">
                                <input
                                    className="input_form"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={"E-mail"}
                                    type="email"
                                />
                            </div>
                            <div className="input_container">
                                <InputMask
                                    className={validationFlag.phone ? "phone_mask error" : "phone_mask"}
                                    value={phone}
                                    alwaysShowMask={true}
                                    onChange={(e) => setPhone(e.target.value)}
                                    mask={"+7 (999) 999-99-99"}
                                />
                                <div className="required">*</div>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={flagSelectService ? "select open" : "select"}
                                tabIndex={1}
                                onClick={() => setFlagSelectService(!flagSelectService)}
                                onBlur={() => setFlagSelectService(false)}
                            >
                                {activeSelectService
                                    ? <div className="label active">{activeSelectService}</div>
                                    : <div className="label">Выберите услугу</div>
                                }
                                <div className="arrow">
                                    <img src={arrow} alt="arrow"/>
                                </div>
                                <div className={flagSelectService ? "select_children open" : "select_children"}>
                                    {dataServices?.length > 0 &&
                                        dataServices.map((elem, indx) =>
                                            <div
                                                className={activeSelectService === elem.name ? "active" : ""}
                                                onClick={() => {
                                                    setActiveSelectService(elem.name)
                                                }}
                                                key={indx}
                                            >
                                                {elem.name}
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className="input_container">
                                <div
                                    className={cn("select ", {
                                        open: flagSelect,
                                        error: validationFlag.budget
                                    })}
                                    style={{ width: "100%"}}
                                    tabIndex={0}
                                    onClick={() => setFlagSelect(!flagSelect)}
                                    onBlur={() => setFlagSelect(false)}
                                >
                                    {activeSelect
                                        ? <div className="label active">{activeSelect}</div>
                                        : <div className="label">Укажите примерный бюджет</div>
                                    }
                                    <div className="arrow">
                                        <img src={arrow} alt="arrow"/>
                                    </div>
                                    <div className={flagSelect ? "select_children open" : "select_children"}>
                                        {salary.map((elem, indx) =>
                                            <div
                                                className={activeSelect === elem ? "active" : ""}
                                                onClick={() => {
                                                    setActiveSelect(elem)
                                                }}
                                                key={indx}
                                            >
                                                {elem}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="required">*</div>
                            </div>
                            <label
                                className="upload_file"
                                htmlFor={"upload"}
                            >
                                <span>Прикрепить файл</span>
                                <img src={file_upload} alt="file_upload"/>
                            </label>
                            <input
                                type="file"
                                style={{display: "none"}}
                                id={"upload"}
                                key={keyInput}
                                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                onChange={(event) => {
                                    if (event?.target?.files?.length > 0)
                                        setFile(event.target.files[0])
                                }}
                            />
                        </div>
                        <div className="row">
                            <div
                                className="input_container"
                                style={{ width: "100%"}}
                            >
                            <textarea
                                className={validationFlag.message ? "text_area error" : "text_area"}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                rows="4"
                                placeholder={"Сообщение"}
                            />
                                <div className="required">*</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="disclosure_agreement">
                                <div className="checkbox_group">
                                    <input
                                        type="checkbox"
                                        id={"checkbox"}
                                        checked={checkedAgreement}
                                        onChange={(event) => setCheckedAgreement(event.target.checked)}
                                    />
                                    <label htmlFor={"checkbox"}>
                                        <div className="text">
                                            Отправляя данные вы даете свое согласие на обработку персональных данных
                                        </div>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div
                                className={checkedAgreement ? "btn_send" : "btn_send disabled"}
                                onClick={() => {
                                    if (checkedAgreement)
                                        handleSendApplication()
                                }
                                }
                            >
                                {loadingUpload
                                    ? <Spin/>
                                    : "Отправить"
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer
                dataServices={dataServices}
                setRedirectService={setRedirectService}
            />
        </div>
    );
};

export default StartProject;