import React, { useRef, useState } from 'react';

import laptop_mobile from "../../assets/laptop_mobile.png";
import './Preview.scss';

const Preview = ({dataCompany, refPreview}) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    let whatsappOpen = () => {
        window.open("https://api.whatsapp.com/send?phone=79640768777&text=Здравствуйте! Пишу из сайта Skillometer.it.", "_blank")
    }
    return (
        <div
            className="preview"
            ref={refPreview}
        >
            <div className="container">
                <div className="left_col">
                    <div className="title">
                        <div>Разработка</div>
                        <div>
                            <span>&</span>
                            <span>Поддержка</span>
                        </div>
                    </div>
                    <div className="info">
                        {dataCompany?.activity_info}
                    </div>
                    <div
                        className="redirect_whatsapp"
                        onClick={whatsappOpen}
                    >
                        <span>Написать в WhatsApp</span>
                    </div>
                    {/*<div className="feedback_form">
                        <div className="discount_info">Оставьте заявку <br/>
                            и получите 10% скидки
                        </div>
                        <input
                            className="input_form"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={"Имя"}
                            type="text"
                        />
                        <InputMask
                            className={"phone_mask"}
                            value={phone}
                            alwaysShowMask={true}
                            onChange={(e) => setPhone(e.target.value)}
                            mask={"+7 (999) 999-99-99"}
                        />
                        <div className="month_end-discount">
                            Акция доступна только в Июне
                        </div>
                        <div className="btn_send">
                            <span>Отправить</span>
                        </div>
                        <div className="disclosure_agreement">
                            Отправляя данные формы вы даете свое согласие на обработки персональных данны
                        </div>
                    </div>*/}
                </div>
                <div className="right_col" />
            </div>
        </div>
    );
};

export default Preview;