import React from 'react';

import gradient from '../../assets/gradient.png';
import contacts_1 from '../../assets/contacts_1.svg';
import contacts_2 from '../../assets/contacts_2.svg';
import contacts_3 from '../../assets/contacts_3.svg';
import hand from '../../assets/hand.png';

import './CompanyModal/CompanyModal.scss';
import './CompanyAbout.scss';

const CompanyAbout = ({ setOpenModal, dataCompany, refCompanyAbout}) => {
    const listContacts = [
        {icon: contacts_1, value: `WhatApp / ${dataCompany?.phone}`},
        {icon: contacts_2, value: `E-mail / ${dataCompany?.email}`},
        {icon: contacts_3, value: dataCompany?.mailing_address}
    ]
    return (
        <div
            className="company_about"
            ref={refCompanyAbout}
        >
            <div className="container">
                <div className="h1">Компания</div>
                <main className="main">
                    <div className="left_col">
                        <div className="info">
                            {dataCompany?.description}
                        </div>
                        <ul className="list">
                            {dataCompany?.key_indicators?.length > 0 &&
                                dataCompany.key_indicators.map((elem, indx) =>
                                    <li key={indx}>
                                        <div className="count">
                                            <span>{elem.title}</span>
                                        </div>
                                        <div className="title">
                                            <span>{elem.description}</span>
                                        </div>
                                    </li>
                                )}
                        </ul>
                    </div>
                    <div className="right_col">
                        <div className="contacts">
                            <ul className="list">
                                {listContacts.map((elem, indx) =>
                                    <li key={indx}>
                                        <img src={elem.icon} alt="contacts"/>
                                        <span>{elem.value}</span>
                                    </li>
                                )}

                            </ul>
                        </div>
                        <div
                            className="hand_group"
                            onClick={() => setOpenModal(true)}
                        >
                            {/* <img src={figure} alt="figure"/>*/}
                            <img src={hand} alt="hand"/>
                        </div>
                    </div>
                </main>
            </div>
            <div
                className="gradient_background"
                style={{background: `url(${gradient}) no-repeat`}}
            />
         {/*   <div className="gradient_parent">
                <div className="gradient"></div>
            </div>*/}

        </div>
    );
};

export default CompanyAbout;