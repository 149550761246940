import axios from "axios";
import { message } from "antd";

const defaultSettings = {
    baseURL: process.env.REACT_APP_API_URL
};
const apiClient = axios.create(defaultSettings);

let responseSuccessHandler = (response) => response;
let responseErrorHandler = (error) => Promise.reject(error);

apiClient.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
);
export default {
    fetch_company_info() {
        return apiClient.get("/api/v1/landing/company_info/")
    },
    fetch_services() {
        return apiClient.get("/api/v1/landing/service/")
    },
    fetch_application(request, progress) {
        return apiClient.post("/api/v1/application/application/", request, {
            headers: 'Content-Type: multipart/form-data',
            onUploadProgress: progress
        })
    },
    fetch_products() {
        return apiClient.get("/api/v1/landing/product/")
    },
    fetch_projects() {
        return apiClient.get("/api/v1/landing/project/")
    },
    fetch_projects_id(id) {
        return apiClient.get(`/api/v1/landing/project/${id}/`)
    },
    fetch_products_id(id) {
        return apiClient.get(`/api/v1/landing/product/${id}/`)
    }
}