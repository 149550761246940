import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Transition } from "react-transition-group";
import InputMask from "react-input-mask";
import requests from "../../axios/requests";
import { message as messageAntd } from "antd";

import burger from '../../assets/burger.svg';
import './Header.scss';

const Header = ({openModal, scrollToView}) => {
    const [openBurger, setOpenBurger] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const navigation = useNavigate();

    let handleSendApplication = () => {
        if (!name)
            messageAntd.error("Заполните имя", 2);
        if (!phone || phone.includes("_"))
            messageAntd.error("Укажите валидный номер", 2);

        let data = new FormData();
        data.append("name", name)
        data.append("phone", phone)
        requests.fetch_application(data)
            .then(() => messageAntd.success("Заявка успешно отправлена."))
            .catch(err => {
                if (err?.response?.data?.phone?.length > 0) {
                    messageAntd.error(err.response.data.phone[0])
                }
                console.error(err)
            })
    }

    function getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    let scrollTo = (current) => {
        if (window.location.href.includes("release")) {
            navigation("/")
            setTimeout(() => scrollToView(current), 50)
        } else
            scrollToView(current)
    }

    return (
        <div className={`header ${openModal ? "hide" : ""}`}
        >
            <div className="container">
                <div
                    className="logo"
                    onClick={() => scrollTo("preview")}
                >
                    SKILLOMETER IT
                </div>
                <ul className="navigation">
                    <li onClick={() => scrollTo("service")}>Услуги</li>
                    <li onClick={() => scrollTo("products")}>Продукты</li>
                    <li onClick={() => scrollTo("releaseProjects")}>Проекты</li>
                    <li onClick={() => scrollTo("aboutCompany")}>О компании</li>
                    {/*<li onClick={() => pageApi.moveTo(4)}>Контакты</li>*/}
                </ul>
                <div
                    className="burger"
                    onClick={() => setOpenBurger(!openBurger)}
                >
                    <img src={burger} alt="burger"/>
                </div>
                <div
                    className="send_request"
                    onClick={() => scrollTo("feedback")}
                >
                    <span>Оставить заявку</span>
                </div>
                <Transition
                    in={openBurger}
                    timeout={400}
                    unmountOnExit
                    mountOnEnter
                >
                    {(state) => (
                        <ul className={`drop_menu ${state}`}>
                            <li onClick={() => scrollToView("service")}>Услуги</li>
                            <li onClick={() => scrollToView("products")}>Продукты</li>
                            <li onClick={() => scrollToView("releaseProjects")}>Проекты</li>
                            <li onClick={() => scrollToView("aboutCompany")}>О компании</li>
                            <li>
                                <div className="feedback_form">
                                    <div className="discount_info">
                                        Оставьте заявку <br/>
                                        и получите 10% скидки
                                    </div>
                                    <input
                                        className="input_form"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder={"Имя"}
                                        type="text"
                                    />
                                    <InputMask
                                        className={"phone_mask"}
                                        value={phone}
                                        alwaysShowMask={true}
                                        onChange={(e) => setPhone(e.target.value)}
                                        mask={"+7 (999) 999-99-99"}
                                    />
                                    <div className="month_end-discount">
                                        Акция доступна только в Июне
                                    </div>
                                    <div
                                        className="btn_send"
                                        onClick={handleSendApplication}
                                    >
                                        <span>Отправить</span>
                                    </div>
                                    <div className="disclosure_agreement">
                                        Отправляя данные формы вы даете свое согласие
                                        на обработку персональных данных
                                    </div>
                                </div>
                            </li>
                        </ul>
                    )}
                </Transition>
            </div>
        </div>
    );
};

export default Header;