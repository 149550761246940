import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import requests from "../../axios/requests";
import './PageRelease.scss';

import first_slide from '../../assets/first_slide.png';
import img_1 from '../../assets/img_1.png';
import img_2 from '../../assets/img_2.png';
import img_3 from '../../assets/img_3.png';
import img_4 from '../../assets/img_4.png';
import img_5 from '../../assets/img_5.png';
import img_6 from '../../assets/img_6.png';

const PageRelease = () => {
    const params = useParams();
    const [data, setData] = useState({});

    let fetchProjects = () => {
        requests.fetch_projects_id(params.id)
            .then(res => setData(res.data))
            .catch(err => console.error(err))
    }
    let fetchProducts = () => {
        requests.fetch_products_id(params.id)
            .then(res => setData(res.data))
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (params?.id) {
            if (window.location.href.includes("release-project"))
                fetchProjects()
            else fetchProducts()
        }
        requests.fetch_projects()
    }, [])
    return (
        <div className="page_release">
            {data?.description &&
                <div dangerouslySetInnerHTML={{__html: data.description}}/>
            }
            {/*   <div className="preview_img">
                <img src={first_slide} alt="first_slide"/>
                <div className="info">
                    <div className="h1">
                        рудн
                    </div>
                    <div className="text">
                        Личный кабинет для оформления заявки при трудоустрйостве
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="text_preview">
                    Мы являемся стратегическим партнером для наших клиентов. Мы поможем вам до конца, спроектируем и
                    внедрим ваш продукт от концепции до итеративной поддержки разработки, всегда проявляя инициативу и
                    работая проактивно.
                </div>
                <div className="slide_1 img">
                    <img src={img_1} alt="img_1"/>
                </div>
                <div className="info_row">
                    <span>
                        Заголовок
                    </span>
                    <span>
                        Мы являемся стратегическим партнером для наших клиентов. Мы поможем вам до конца,
                        спроектируем и внедрим ваш продуктот концепции до итеративной поддержки разработки,
                        всегда проявляя инициативу и работая проактивно.
                    </span>
                </div>
                <div className="slide_2 img">
                    <img src={img_2} alt="img_2"/>
                    <div>
                        <img src={img_3} alt="img_3"/>
                        <img src={img_4} alt="img_4"/>
                    </div>
                </div>
                <div className="info_row">
                    <span>
                        Заголовок
                    </span>
                    <span>
                        Мы являемся стратегическим партнером для наших клиентов. Мы поможем вам до конца,
                        спроектируем и внедрим ваш продуктот концепции до итеративной поддержки разработки,
                        всегда проявляя инициативу и работая проактивно.
                    </span>
                </div>
                <div className="slide_3 img">
                    <img src={img_5} alt="img_5"/>
                </div>
                <div className="info_row">
                    <span>
                        Заголовок
                    </span>
                    <span>
                        Мы являемся стратегическим партнером для наших клиентов. Мы поможем вам до конца,
                        спроектируем и внедрим ваш продуктот концепции до итеративной поддержки разработки,
                        всегда проявляя инициативу и работая проактивно.
                    </span>
                </div>
                <div className="slide_4 img">
                    <img src={img_6} alt="img_6"/>
                </div>
            </div>*/}
        </div>
    );
};

export default PageRelease;