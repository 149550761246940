import React from 'react';
import { Transition } from "react-transition-group";
import close from '../../../assets/close.svg';

const CompanyModal = ({openModal, closeModal, dataCompany}) => {
    let currentKey = (str) => {
        switch (str) {
            case "full_name":
                return "Полное наименование: ";
            case "short_name":
                return "Сокращенное наименование: ";
            case "legal_address":
                return "Юридический адрес: ";
            case "mailing_address":
                return "Почтовый адрес: ";
            case "phone_fax":
                return "Телефон/факс:  ";
            case "inn_kpp":
                return "ИНН/КПП: ";
            case "ogrn":
                return "ОГРН: ";
            case "checking_account":
                return "Расчетный счет: ";
            case "cor_account":
                return "Корреспондентский счет: ";
            case "bik":
                return "БИК банка: ";
            case "bank_name":
                return "Банк: ";
            case "ceo":
                return "Генеральный директор: ";
            default:
                return ""
        }
    }

    return (
        <Transition
            in={openModal}
            timeout={400}
            unmountOnExit
            mountOnEnter
        >
            {state =>
                <div className={`modal_parent ${state}`}>
                    <div className="container">
                        <div className="h1">
                            <span>реквизиты</span>
                            <div
                                className="close"
                                onClick={closeModal}
                            >
                                <img src={close} alt="close"/>
                            </div>
                        </div>
                        <div className="content">
                            {Object.keys(dataCompany).length > 0 &&
                                Object.keys(dataCompany).map((key, indx) => {
                                        if (key !== "key_indicators" && key !== "phone" && key !== "description"
                                            && key !== "email" && key !== "address"  && key !== "name" &&
                                            key !== "activity_info"
                                        )
                                            return (
                                                <div className="row" key={indx}>
                                                    <div className="label">{currentKey(key)}</div>
                                                    <div className="value">{dataCompany[key]}</div>
                                                </div>
                                            )
                                    }
                                )
                            }
                        </div>
                    </div>
                </div>
            }
        </Transition>
    );
};

export default CompanyModal;