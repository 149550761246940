import React from 'react';
import { useNavigate } from "react-router-dom";

import sst from "../../assets/sst_icon.png";
import inteh from "../../assets/inteh_icon.png";
import rudn from "../../assets/rudn_icon.png";
import './ReleaseProjects.scss';

const ReleaseProjects = ({refReleaseProjects, scrollToTop}) => {
    const navigate = useNavigate();
    return (
        <div
            className="release_projects"
            ref={refReleaseProjects}
        >
            <div className="container">
                <div className="title">
                    <span>реализованные</span>
                    <span>проекты</span>
                </div>
                <div className="content">
                    <div className="left_col">
                        <div
                            className="preview_image"
                            onClick={() => window.open("https://ss-trans.tech/", "_blank")}
                        >
                            <img src={sst} className="mobile" alt="sst"/>
                        </div>
                        <div
                            className="name"
                            onClick={() => {
                                navigate("/release-project/1")
                                scrollToTop();
                               /*window.open("https://ss-trans.tech/", "_blank")*/
                            }
                            }
                        >
                            СахаСпецТранс
                        </div>
                        <div className="info">Платформа для диспетчеризации заявок на услуги спецтехники.</div>
                        <div
                            className="preview_image"
                            onClick={() => window.open("https://its96.ru/", "_blank")}
                        >
                            <img src={inteh} className="mobile" alt="inteh"/>
                        </div>
                        <div
                            className="name"
                            onClick={() => {
                                navigate("/release-project/2")
                                scrollToTop();
                                /*window.open("https://its96.ru/", "_blank")*/
                            }}
                        >
                            Интехстрой
                        </div>
                        <div className="info">Посадочная страница для приема заявок на поставку стройматериалов.</div>
                    </div>
                    <div className="right_col">
                        <div
                            className="preview_image"
                            onClick={() =>
                                window.open("https://vote.rudn.ru/", "_blank")}
                        >
                            <img src={rudn} className="mobile" alt="rudn"/>
                        </div>
                        <div
                            className="name"
                            onClick={() => {
                                navigate("/release-project/3")
                                scrollToTop();
                            }}
                        >
                            РУДН
                        </div>
                        <div className="info">Платформа для проведения выборов профессорско-преподавательского состава
                            РУДН.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReleaseProjects;