import React from 'react';
import './Footer.scss';

const Footer = ({dataServices, setRedirectService}) => {
    return (
        <div className="footer">
            <div className="col">
                <div className="h1">
                    SKILLOMETER IT
                </div>
                <div className="text">
                    Мы помогаем стартапам и владельцам бизнеса сделать визуальное заявление с помощью безупречного
                    веб-дизайна
                    и разработки, чтобы они могли повысить узнаваемость бренда и продавать больше.
                </div>
            </div>
            <div className="col">
                <div className="h1">
                    уСЛУГИ
                </div>
                <div className="list">
                    {(dataServices && dataServices?.length > 0) &&
                        dataServices.map((elem, indx) =>
                            <li
                                key={indx}
                                onClick={() => setRedirectService(elem.name)}
                            >
                                {elem.name}
                            </li>
                        )
                    }
                </div>
            </div>
            <div className="col">
                <div className="h1">
                    пРОЕКТЫ
                </div>
                <div className="list">
                    <li>СахаСпецТранс</li>
                    <li>Интехстрой</li>
                    <li>РУДН</li>
                </div>
            </div>
        </div>
    );
};

export default Footer;