import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";

import list_1 from '../../assets/list_1.png';
import list_2 from '../../assets/list_2.png';
import list_3 from '../../assets/list_3.png';
import checkbox from '../../assets/checkbox.svg';
import './Service.scss';

const ServiceItem = (props) => {
    const {index, elem, setRedirectService, height} = props;
    return (
        <li
            className={`${index === 1 ? "list_elem active" : "list_elem"}`}
            key={index}
        >
            <div className={`content`}>
                <div
                    className={`item`}
                    style={height
                        ? {minHeight: height}
                        : {}
                    }
                >
                    <div className="icon">
                        <img
                            src={elem.icon}
                            alt="item"
                        />
                    </div>
                    <div className="label">
                        {elem.name}
                    </div>
                    <div className="inner">
                        <div className="info">
                            {elem.description}
                        </div>
                        {elem.item_list.length > 0 &&
                            elem.item_list.map((value, indx) =>
                                <div
                                    className="service_item"
                                    key={indx}
                                >
                                    <img src={checkbox} alt="checkbox"/>
                                    <span>{value}</span>
                                </div>
                            )}
                    </div>
                    <div
                        className="btn_send"
                        onClick={() => setRedirectService(elem.name)}
                    >
                        <span>Оставить заявку</span>
                    </div>
                </div>
            </div>
        </li>
    )
}
const Service = ({dataServices, refServices,setRedirectService}) => {
    const [activeDots, setActiveDots] = useState(0);
    const [sliderHeight, setSliderHeight] = useState(0);

    const refSliderContainer = useRef();
    const refSlider = useRef();
    const settingsSlider = {
        infinite: true,
        speed: 700,
        slidesToShow: getCountShowSlide(),
        slidesToScroll: 1,
        /* rows: 1,
         swipeToSlide: true,*/
        className: "slides_mobile",
        arrows: false,
    };

    let getCurrentHeight = () => {
        if (refSliderContainer?.current) {
            const {height} = refSliderContainer.current.getBoundingClientRect();
            if (height)
                setSliderHeight(height)
        }
    }

    function getCountShowSlide() {
        /* if (window.innerWidth < 405) {
             return 1.05
         }*/
        if (window.innerWidth < 420) {
            return 1
        }
        if (window.innerWidth < 445) {
            return 1.04
        }
        if (window.innerWidth < 480) {
            return 1.06
        }
        if (window.innerWidth < 790) {
            return 1.05
        }
        if (window.innerWidth < 950) {
            return 2
        }
        return 2
    };

    useEffect(() => {
        if (dataServices.length > 0) {
            setTimeout(() => {
                getCurrentHeight();
            }, 100)
        }
    }, [dataServices])

    return (
        <div
            className="service"
            ref={refServices}
        >
            <div className="container">
                <div
                    className="title web"
                    ref={refServices}
                >
                    <div className="h1">Услуги</div>
                    <div className="h1">Нашей</div>
                    <div className="h1">Студии</div>
                </div>
                <ul className="list web">
                    {dataServices?.length > 0 &&
                        dataServices.slice(0, 3).map((elem, index) =>
                            <ServiceItem
                                key={index}
                                index={index}
                                elem={elem}
                                setRedirectService={setRedirectService}
                            />
                        )
                    }
                </ul>
                <div className="title_mobile">
                    <div className="row">
                        <div>Услуги</div>
                        <div>Студии</div>
                    </div>
                    <div className="row">
                        <div>Нашей</div>
                    </div>
                </div>
                {dataServices?.length > 0 &&
                    <>
                        <div
                            className="slider_section"
                            ref={refSliderContainer}
                        >
                            <Slider
                                {...settingsSlider}
                                beforeChange={(_, indx) => {
                                    setActiveDots(indx)
                                }}
                                ref={refSlider}
                            >
                                {dataServices.slice(0, 3).map((elem, index) =>
                                    <ServiceItem
                                        key={index}
                                        index={index}
                                        elem={elem}
                                        setRedirectService={setRedirectService}
                                        height={sliderHeight}
                                    />
                                )}
                            </Slider>
                        </div>
                        <div className="dots">
                            {Array(3).fill(0).map((_, indx) =>
                                <div
                                    onClick={() => {
                                        setActiveDots(indx)
                                        refSlider?.current?.slickGoTo(indx)
                                    }}
                                    className={activeDots === indx ? "active" : ""}
                                    key={indx}
                                />
                            )}
                        </div>
                    </>
                }
            </div>
            <div className="gradient_parent">
                <div className="gradient"></div>
            </div>
        </div>
    );
};

export default Service;